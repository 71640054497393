import React from 'react';
import Navbar from './Components/Navbar/Navbar';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Inicio from './pages/Inicio';
import Acerca from './pages/Acerca';
import Gimnasio from './pages/Gimnasio';
import EntrenamientoPersonal from './pages/EntrenamientoPersonal';
import ContactUs from './Components/ContactUs/ContactUs';
import ScrollToTop from './Components/ScrollToTop/ScrollToTop';


/*import Gimnasio from './pages/Gimnasio';
import Blog from './pages/Blog';

          <Route path="/gimnasio" element={<Gimnasio />} />
          <Route path="/blog" element={<Blog />} />*/
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme';
import Fab from '@mui/material/Fab';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import ReactGA from 'react-ga';

const App = () => {
  ReactGA.initialize('G-GBK1QVVJC4'); // Reemplaza con tu propio código de seguimiento

  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
      <ScrollToTop />
        <Navbar theme={theme} />
        <Routes>
          <Route path="/" element={<Inicio />} />
          <Route path="/acerca" element={<Acerca />} />
          <Route path="/gimnasio" element={<Gimnasio />} />
          <Route path="/entrenamiento-personal" element={<EntrenamientoPersonal />} />

        </Routes>
      </BrowserRouter>

      <div style={{
          position: 'fixed',
          bottom: '16px',
          right: '16px',
          zIndex: 1000,
        }}>
          <Fab
            color="primary"
            href="https://wa.me/+34692871659"
            target="_blank"
            rel="noopener noreferrer"
          >
            <WhatsAppIcon />
          </Fab>
        </div>
        <ContactUs/>
    </ThemeProvider>
  );
};

export default App;
