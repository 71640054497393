// src/pages/EntrenamientoPersonal/EntrenamientoPersonal.js

import React, { useEffect }from "react";
import BannerH1 from "../../Components/BannerH1/BannerH1";
import CenteredText from "../../Components/CenteredText/CenteredText";
import CardSection3 from "../../Components/CardSection3/CardSection3";
import ImageGallery from "../../Components/ImageGallery/ImageGallery";
import WaveSeparator from "../../Components/WaveSeparator/WaveSeparator";

import imgCardapp from "../../assets/FotosGimnasio/cards/app.jpg";
import imgCardfingerprint from "../../assets/FotosGimnasio/cards/fingerprint.jpg";
import imgCardLimitacionAforo from "../../assets/FotosGimnasio/cards/LimitacionAforo.jpg";

import imgCardculturismo from "../../assets/FotosGimnasio/cards/culturismo.jpg";
import imgCardnutricion from "../../assets/FotosGimnasio/cards/nutricion.jpg";

import backgroundImg from "../../assets/Images/background.jpg"; // Ajusta la ruta según la ubicación de tu imagen de fondo
import Img1 from "../../assets/FotosGimnasio/resized/DSC01488-3-min.jpg";
import Img2 from "../../assets/FotosGimnasio/resized/DSC01489-4-min.jpg";
import Img3 from "../../assets/FotosGimnasio/resized/DSC01490-5-min.jpg";
import Img4 from "../../assets/FotosGimnasio/resized/DSC01492-6-min.jpg";
import Img5 from "../../assets/FotosGimnasio/resized/DSC01495-9-min.jpg";
import Img6 from "../../assets/FotosGimnasio/resized/DSC01497-11.jpg";
import Img7 from "../../assets/FotosGimnasio/resized/DSC01505-16.jpg";
import Img8 from "../../assets/FotosGimnasio/resized/DSC01507-17.jpg";
import Img9 from "../../assets/FotosGimnasio/resized/DSC01514-20.jpg";
import Img10 from "../../assets/FotosGimnasio/resized/DSC01523-27.jpg";
import Img11 from "../../assets/FotosGimnasio/resized/DSC01524-28.jpg";

import theme from "../../theme";
import ReactGA from "react-ga";

const FacilidadesCards = [
  {
    title: "APP para reservas",
    description: "Simplificamos la reserva y seguimiento de tus sesiones de entrenamiento. Asegura tu lugar en el gimnasio de manera conveniente con nuestra aplicación.",
    image: imgCardapp,
    backgroundColor: theme.palette.card.background, // o cualquier otro color válido en el tema de Material-UI
    textColor: theme.palette.card.textColor, // o cualquier otro color válido en el tema de Material-UI
  },
  {
    title: "Lector de huella",
    description: 'Accede de manera segura y sin complicaciones a nuestras instalaciones con solo un toque. Olvídate de las tarjetas y experimenta la comodidad.',
    image: imgCardfingerprint,
    backgroundColor: theme.palette.card.background, // o cualquier otro color válido en el tema de Material-UI
    textColor: theme.palette.card.textColor, // o cualquier otro color válido en el tema de Material-UI
  },
  {
    title: "AFORO LIMITADO",
    description:
      "Disfruta de un ambiente exclusivo y seguro en nuestro gimnasio. Con un aforo limitado, garantizamos tu comodidad y seguridad mientras entrenas. Olvídate de las multitudes y aprovecha al máximo tu experiencia de entrenamiento.",
    image: imgCardLimitacionAforo,
    backgroundColor: theme.palette.card.background, // o cualquier otro color válido en el tema de Material-UI
    textColor: theme.palette.card.textColor, // o cualquier otro color válido en el tema de Material-UI
  },
];

const ServiciosCards = [
  {
    title: "Culturismo natural",
    description:    "Nuestro enfoque de culturismo es natural y saludable. Te ayudamos a alcanzar tus metas de acondicionamiento físico de forma segura y auténtica, sin sustancias artificiales.",
    image: imgCardculturismo,
    backgroundColor: theme.palette.card.background, // o cualquier otro color válido en el tema de Material-UI
    textColor: theme.palette.card.textColor, // o cualquier otro color válido en el tema de Material-UI
  },
  {
    title: "Seguimiento deportivo",
    description: "Nuestro servicio de seguimiento deportivo te ayuda a alcanzar tus objetivos de rendimiento. Ofrecemos un enfoque personalizado y basado en datos para mejorar tu entrenamiento y desempeño. Confía en nosotros para elevar tu rendimiento.",
    image: "https://onpointweb.es/wp-content/uploads/2022/01/Sin-titulo-3.jpg",
    backgroundColor: theme.palette.card.background, // o cualquier otro color válido en el tema de Material-UI
    textColor: theme.palette.card.textColor, // o cualquier otro color válido en el tema de Material-UI
  },
  {
    title: "Nutrición y dietética",
    description: "Nuestro servicio de nutrición y dietética te apoya en tus metas de bienestar con orientación personalizada y un enfoque en la nutrición saludable. Alcanza un estado óptimo de salud y bienestar con nosotros.",
    image: imgCardnutricion,
    backgroundColor: theme.palette.card.background, // o cualquier otro color válido en el tema de Material-UI
    textColor: theme.palette.card.textColor, // o cualquier otro color válido en el tema de Material-UI
  },
];

const images = [
  {
    src: Img1,
    alt: "Descripción de la imagen 1",
    title: "Título de la imagen 1",
  },
  {
    src: Img2,
    alt: "Descripción de la imagen 1",
    title: "Título de la imagen 1",
  },
  {
    src: Img3,
    alt: "Descripción de la imagen 1",
    title: "Título de la imagen 1",
  },
  {
    src: Img4,
    alt: "Descripción de la imagen 1",
    title: "Título de la imagen 1",
  },
  {
    src: Img5,
    alt: "Descripción de la imagen 1",
    title: "Título de la imagen 1",
  },
  {
    src: Img6,
    alt: "Descripción de la imagen 1",
    title: "Título de la imagen 1",
  },
  {
    src: Img7,
    alt: "Descripción de la imagen 1",
    title: "Título de la imagen 1",
  },
  {
    src: Img8,
    alt: "Descripción de la imagen 1",
    title: "Título de la imagen 1",
  },
  {
    src: Img9,
    alt: "Descripción de la imagen 1",
    title: "Título de la imagen 1",
  },
  {
    src: Img10,
    alt: "Descripción de la imagen 1",
    title: "Título de la imagen 1",
  },
  {
    src: Img11,
    alt: "Descripción de la imagen 1",
    title: "Título de la imagen 1",
  },
];





const Gimnasio = () => {

  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);

  return (
    <div>
      <BannerH1 height={800} title="Gimnasio MesVida" backgroundImg={Img2} />

      <CenteredText
        title="¡Adentrate en nuestra experiencia como Gimnasio Independiente de calidad!"
        text="En nuestro gimnasio independiente, te ofrecemos una experiencia única que te mantendrá siempre motivado y desafiado. Con un amplio surtido de máquinas de alta calidad y el respaldo de nuestros entrenadores altamente capacitados, no solo alcanzarás tus objetivos de acondicionamiento físico, sino que también aprenderás a mantener un estilo de vida saludable y sostenible. ¡No esperes más! Da el primer paso hacia la mejor versión de ti mismo y descubre el poder de un gimnasio independiente con una atención sin igual. Únete a nosotros y experimenta la diferencia de tener un compañero en cada etapa de tu viaje hacia la transformación!"
        backgroundColor={theme.palette.background.primary}
      />

      <WaveSeparator
        fillColor={theme.palette.background.primary}
        bgColor={theme.palette.background.secondary}
      />

      <CardSection3
        title="Nuestras facilidades"
        cardsInfo={FacilidadesCards}
        bckgrColor={theme.palette.background.secondary}
      />
      <WaveSeparator
        fillColor={theme.palette.background.secondary}
        bgColor={theme.palette.background.primary}
      />

      <CardSection3
        title="Nuestros servicios"
        cardsInfo={ServiciosCards}
        bckgrColor={theme.palette.background.primary}
      />
      <WaveSeparator
        fillColor={theme.palette.background.primary}
        bgColor={theme.palette.background.secondary}
      />

      <ImageGallery
        images={images}
        columns={3}
        bckground={theme.palette.background.secondary}
      />
      <WaveSeparator
        fillColor={theme.palette.background.secondary}
        bgColor={theme.palette.background.primary}
      />
    </div>
  );
};

export default Gimnasio;
