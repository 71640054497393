import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#010101', // Cambia esto al color principal que desees
    },
    secondary: {
      main: '#ec3642', // Cambia esto al color secundario que desees
    },
    tertiary: {
      main: '#ffffff',
    },
    background: {
      default: '#333333',
      primary: '#333333',
      secondary: '#222222',
    },
    card:{
      background:'#ec3642',
      textColor:'white'
    }
  },
  typography: {
    fontFamily: [
      'Roboto',
      'sans-serif',
    ].join(','),
    h1: {
      fontSize: '3rem',
      fontWeight: 700,
      marginBottom: '1rem',
    },
    h2: {
      fontSize: '2rem',
      fontWeight: 700,
      marginBottom: '4rem',
    },
    h3: {
      fontSize: '1.5rem',
      fontWeight: 700,
      marginBottom: '1rem',
    },
    h4: {
      fontSize: '1.25rem',
      fontWeight: 700,
      marginBottom: '1rem',
    },
    h5: {
      fontSize: '1rem',
      fontWeight: 700,
      marginBottom: '1rem',
    },
    body1: {
      fontSize: '1rem',
      lineHeight: 1.5,
      marginBottom: '1rem',
    },
    button: {
      fontSize: '1rem',
      textTransform: 'capitalize',
      padding: '0.75rem 2rem',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '40px',
        },
        contained: {
          boxShadow: 'none',
          '&:hover': {
            boxShadow: 'none',
          },
        },
        outlined: {
          borderWidth: '2px',
        },
        text: {
          padding: '0.75rem 1rem',
        },
      },
    },
  },
});


export default theme;
