import React, { useState, useEffect } from 'react';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Paper from '@mui/material/Paper';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
// Reemplaza esto con los datos reales de tus comentarios

  
  const comments = ({ comment }) => (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Paper sx={{ p: 2, borderRadius: 2, bgcolor: 'white', mb: 1 }}>
        <Typography variant="body1" sx={{ fontStyle: 'italic', textAlign: 'center' }}>
          {comment.text}
        </Typography>
      </Paper>
      <Avatar alt={comment.name} src={comment.image} />
      <Typography variant="subtitle2" color='white'>{comment.name}</Typography>
    </Box>
  );
  
  export default comments;