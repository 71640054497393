import React, { useEffect }from 'react';
import BannerInicio from '../../Components/BannerInicio/BannerInicio';
import H2_Imagen from '../../Components/H2_Imagen/H2_Imagen';
import Servicios from '../../Components/Servicios/Servicios';
import ValueSectionComponent from '../../Components/NuestroValor/NuestroValor';
import Testimonials from '../../Components/Testimonials/Testimonials';
import comoFuncionamosImage from '../../assets/Images/como-funcionamos.png'; // Importa tu imagen aquí
import theme from '../../theme';

import WaveSeparator from '../../Components/WaveSeparator/WaveSeparator';

import backgroundImg from '../../assets/Images/background.jpg'; // Ajusta la ruta según la ubicación de tu imagen de fondo


const Acerca = () => {

  <BannerInicio height={800} backgroundImg={backgroundImg} />


};

export default Acerca;
