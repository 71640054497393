import React from 'react';
import {
  Box,
  Grid,
  Typography,
  Container
} from '@mui/material';
import Card from '../../Components/Card/Card';
import { useTheme } from '@mui/system';

const CardSection3 = ({ title, cardsInfo, bckgrColor }) => {
  const theme = useTheme();

  return (
    <Box py={5} sx={{ backgroundColor: bckgrColor }}>
      <Container maxWidth="md">
        <Typography variant="h2" textAlign="center" color="white" mb={5}>
          {title}
        </Typography>
        <Grid container spacing={2}>
          {cardsInfo.map((CardInfo, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Box display="flex" justifyContent="center">
                <Card {...CardInfo} />
              </Box>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default CardSection3;
