import React from 'react';
import Button from '@mui/material/Button';
import './BannerInicio.css';
import { useTheme } from '@mui/material/styles';

const BannerInicio = ({ height, backgroundImg }) => {
  const theme = useTheme();

  const handleContactClick = () => {
    const contactSection = document.getElementById('contacto');
    if (contactSection) {
      contactSection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <section className="hero" style={{
      height: height ? `${height}px` : '100vh', // Usamos la prop "height" para establecer la altura del componente
      backgroundColor: theme.palette.primary.main,
    }}>
      <div className="hero-overlay">
        <div className="banner-container">
          <img src={backgroundImg} alt="banner" className="banner-img" />
        </div>
        <div className="banner-content">
          <span

            style={{ color: 'white' }}
          >
            CENTRO ENTRENAMIENTO PERSONAL Y GIMNASIO
          </span>
          <h1

            style={{
              color: theme.palette.secondary.main,
              fontFamily: theme.typography.h1.fontFamily,
            }}
          >
            MESVIDA
          </h1>
          <span

            style={{ color: 'white' }}
          >
            PARA MESVIDA NO ERES UNO MÁS ERES TÚ
          </span>
          <div className="banner-button-container">
            <Button
              variant="contained"
              to="/contacto"
              onClick={handleContactClick} 
              sx={{
                backgroundColor: theme.palette.secondary.main,
                color: theme.palette.tertiary.main,
                '&:hover': {
                  backgroundColor: theme.palette.tertiary.main,
                  color: theme.palette.primary.main,
                },
                fontFamily: theme.typography.button.fontFamily,
              }}
            >
              Contacto
            </Button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BannerInicio;
