import React from 'react';
import './NuestroValor.css'; // Asegúrate de tener un archivo CSS para estilizar el componente
import imgEquipamiento from '../../assets/Images/equipamiento.png';
import imgNutricion from '../../assets/Images/nutricion.png';
import imgMedicion from '../../assets/Images/medicion.png';
import imgAforos from '../../assets/Images/aforos.png';
import { useTheme } from '@mui/material/styles';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid'; // Grid version 1



import imgGymEntPersonal from '../../assets/Images/DSC01492-6-min.jpg';

const NuestroValor = () => {

  const theme = useTheme();

  return (
    <Container maxWidth="lg" sx={{ 
      backgroundColor: theme.palette.background.primary,
      minWidth: '100%', // Establecer la altura mínima a 100% del viewport height
      padding:'2%' }}
    
    >
      <Typography variant="h2" align="center" sx={{ color: 'white'}} >
        Nuestro Valor
      </Typography>
      <Container sx={{maxWidth:'100%'}}>
      <Grid container spacing={1} >
        <Grid item xs={12} sm={6} md={3}>
          <div className={'item'}>
            <img className='image' src={imgEquipamiento} alt="equipamiento" />
            <Typography variant="subtitle1" align="center" color="white">
              Equipamiento de calidad
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
        <div className= {'item'}>
            <img className='image'  src={imgNutricion} alt="nutricion" />
            <Typography variant="subtitle1" align="center" color="white">
              Ayuda nutricional
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
        <div className={'item'}>
            <img className='image' src={imgMedicion} alt="medicion" />
            <Typography variant="subtitle1" align="center" color="white">
              Entrenamiento a medida
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
        <div className={'item'}>
            <img className='image' src={imgAforos} alt="aforos" />
            <Typography variant="subtitle1" align="center" color="white">
              Limitación de aforos
            </Typography>
          </div>
        </Grid>
      </Grid>
      </Container>
    </Container>
  );
};

export default NuestroValor;
