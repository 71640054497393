// src/components/CenteredText/CenteredText.js

import React from 'react';
import { Grid, Typography, Container  } from '@mui/material';

const CenteredText = ({ title, text, backgroundColor }) => {
    return (

        <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="center"
            style={{
                textAlign: 'center',
                padding: '2rem',
                backgroundColor: backgroundColor,

            }}
        >
        <Container maxWidth="md">

                <Grid item xs={12}>
                    <Typography variant="h2" color="white">
                        {title}
                    </Typography>
                </Grid>
                <Grid item xs={12} container justifyContent="center" alignItems="center">
                    <Typography color="white">{text}</Typography>
                </Grid>
                </Container >

        </Grid>

  );
};

export default CenteredText;
