import React, { useEffect } from "react";
import BannerInicio from "../../Components/BannerInicio/BannerInicio";
import H2_Imagen from "../../Components/H2_Imagen/H2_Imagen";
import Servicios from "../../Components/Servicios/Servicios";
import ValueSectionComponent from "../../Components/NuestroValor/NuestroValor";
import Testimonials from "../../Components/Testimonials/Testimonials";
import comoFuncionamosImage from "../../assets/Images/como-funcionamos.png"; // Importa tu imagen aquí
import theme from "../../theme";
import "./index.css";
import WaveSeparator from "../../Components/WaveSeparator/WaveSeparator";
import ReactGA from "react-ga";

import backgroundImg from "../../assets/Images/background.jpg"; // Ajusta la ruta según la ubicación de tu imagen de fondo

const Inicio = () => {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);

  return (
    <>
      <BannerInicio height={800} backgroundImg={backgroundImg} />
      <H2_Imagen
        title="¿Cómo funcionamos?"
        image={comoFuncionamosImage}
        bgColor={theme.palette.background.primary}
      />
      <WaveSeparator
        fillColor={theme.palette.background.primary}
        bgColor={theme.palette.background.secondary}
      />

      <Servicios />
      <WaveSeparator
        fillColor={theme.palette.background.secondary}
        bgColor={theme.palette.background.primary}
      />

      <ValueSectionComponent />
      <WaveSeparator
        fillColor={theme.palette.background.primary}
        bgColor={theme.palette.background.secondary}
      />

      <Testimonials />
      <WaveSeparator
        fillColor={theme.palette.background.secondary}
        bgColor={theme.palette.background.primary}
      />
    </>
  );
};

export default Inicio;
