// src/components/WaveSeparator.js
import React from 'react';

const WaveSeparator = ({ fillColor,bgColor }) => {
  return (
    <svg viewBox="0 0 1440 320" style={{ backgroundColor: bgColor, marginTop: '-10px', marginBottom: '-10px' }}>
      <path
        fill={fillColor}
        d="M0,96L40,122.7C80,149,160,203,240,213.3C320,224,400,192,480,186.7C560,181,640,203,720,213.3C800,224,880,224,960,213.3C1040,203,1120,181,1200,170.7C1280,160,1360,160,1400,160L1440,160L1440,0L1400,0C1360,0,1280,0,1200,0C1120,0,1040,0,960,0C880,0,800,0,720,0C640,0,560,0,480,0C400,0,320,0,240,0C160,0,80,0,40,0L0,0Z"
      ></path>
    </svg>
  );
};

export default WaveSeparator;
