import React from 'react';
import PropTypes from 'prop-types';
import './H2_Imagen.css';
import { Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';



const H2_Imagen = ({ title, image, bgColor }) => {
  const theme = useTheme();

  return (
    <section
      style={{ backgroundColor: bgColor }}
      className="section-container"
    >
      <div className="h2-imagen-container">
        <Typography variant="h2" sx={{ fontFamily: theme.typography.fontFamily }}>
          {title}
        </Typography>
        <div className="section-image-container">
          <img src={image} alt="imagen" className="section-image" />
        </div>
      </div>
    </section>
  );
};


H2_Imagen.propTypes = {
  title: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  bgColor: PropTypes.string.isRequired,
};

export default H2_Imagen;
