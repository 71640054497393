import React from 'react';
import {
    Box,
    Grid,
    Typography,
    Container,
  } from '@mui/material';
  import { useTheme } from '@mui/system';

const ImageGallery = ({ images, columns, bckground }) => {
    const theme = useTheme();

    return (
        <Box sx={{backgroundColor: bckground, paddingBottom:'10px'}}>
            <Container maxWidth="md"  >
            <Typography variant="h2" textAlign="center" color='white' mb={5}>
        Nuestro espacio
        </Typography>
                <Grid container spacing={2} justifyContent="center">
                    {images.map((image, index) => (
                        <Grid item xs={12 / columns} key={index}>
                            <Box
                                component="img"
                                src={image.src}
                                alt={image.alt}
                                title={image.title}
                                loading="lazy"
                                width="100%"
                                height="auto"
                                
                                sx={{ borderRadius: '10px' }}
                            />
                        </Grid>
                    ))}
                </Grid>
            </Container>

        </Box>
    );
};

export default ImageGallery;
