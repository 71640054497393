import React from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import FlipCard from '../../Components/FlipCard/FlipCard';
import imgGymEntPersonal from '../../assets/Images/DSC01492-6-min.jpg'
import imgGym from '../../assets/Images/DSC01550-48-min.jpg'
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';


const Servicios = () => {
  const theme = useTheme();


  return (
    <Container id="servicios" maxWidth="lg" sx={{
      backgroundColor: theme.palette.background.secondary,
      minWidth: '100%', // Establecer la altura mínima a 100% del viewport height
      padding: '2%'
    }}>
      <Container>


        <Typography variant="h2" align="center"  color="white"> {/* Cambiar el color del título a blanco */}
          Nuestros servicios
        </Typography>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} sm={6} container alignItems="center" justifyContent="center">
            <Paper elevation={0} sx={{ bgcolor: 'transparent'/*, width: '80%', height: '80%',  minHeight: '300px', padding: '4%'*/ }}>
              <FlipCard
                image={imgGym}
                title="Entrenamiento personal"
                subtitle="¿Cansado de no obtener resultados en el gimnasio a pesar de tus esfuerzos?"
                text="  ¡Tenemos la solución ideal para ti! Nuestro servicio de Entrenamiento Personal está diseñado para optimizar tus resultados y mantenerte motivado en cada etapa de tu camino hacia una vida saludable."
                buttonText="Ver más"
                link="/entrenamiento-personal"

              />
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6} container alignItems="center" justifyContent="center">
            <Paper elevation={0} sx={{ bgcolor: 'transparent'/*, width: '80%', height: '80%',  minHeight: '300px' */ }}>
              <FlipCard
                image={imgGymEntPersonal}
                title="Gimnasio MesVida"
                subtitle="¿Sufres de falta de tiempo, escasa motivación y ausencia de apoyo?"
                text="Descubre nuestra sala exclusiva, diseñada para brindarte comodidad y permitirte escapar del estrés y el frenesí cotidiano. Si buscas disfrutar de un entrenamiento personalizado, MESVIDA es la opción ideal para ti."
                buttonText="Ver más"
                link="/gimnasio"
              />
            </Paper>
          </Grid>

        </Grid>
      </Container>

    </Container>
  );
};

export default Servicios;
