import React from 'react';
import {
  Typography,
  Card,
  CardContent,
  CardMedia,
} from '@mui/material';
  import { useMediaQuery } from '@mui/material';
  import theme from '../../theme';


const CompCard = ({
    title,
    description,
    image,
    backgroundColor = 'White', // valor predeterminado en caso de que no se pase la prop backgroundColor
    textColor = 'text.primary', // valor predeterminado en caso de que no se pase la prop textColor
  }) => {  
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    
    return (
    <Card sx={{ bgcolor: backgroundColor, height: '90%', width:isMobile?'50%':'100%'}} >
      <CardMedia component="img" height= '200px' image={image} alt={title} />
      <CardContent sx={{height: '200px'}}>
        <Typography variant="h3" component="div" color={textColor} align='center'>
          {title}
        </Typography>
        <Typography variant="body2"  color={textColor} align='center' sx={{textOverflow:'ellipsis'}}>
          {description}
        </Typography>
      </CardContent>
    </Card>
  );
};
export default CompCard;
