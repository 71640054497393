import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './FlipCard.css';
import Button from '@mui/material/Button';
import { useTheme } from '@mui/material/styles';
import { Link, useNavigate } from 'react-router-dom';


const FlipCard = ({ image, title, subtitle, text, buttonText, link }) => {
  const [isFlipped, setIsFlipped] = useState(false);
  const theme = useTheme();

  const handleMouse = () => {
    setIsFlipped(!isFlipped);
  };

  return (
    <div
      className={`FlipCard ${isFlipped ? 'is-flipped' : ''}`}
      onMouseEnter={handleMouse}
      onMouseLeave={handleMouse}
     
      
    >
      <div className="FlipCard-content">
        <img src={image} alt="FlipCard" className="FlipCard-image" />
        <h3 className="FlipCard-title">{title}</h3>
      </div>
      <div className="FlipCard-content back">
        <p className="FlipCard-subtitle">{subtitle}</p>
        <p className="FlipCard-text">{text}</p>
        
        <Button variant="contained"component={Link}  to={link} sx={{
              backgroundColor: theme.palette.secondary.main,
              color: theme.palette.tertiary.main,
              '&:hover': {
                backgroundColor: theme.palette.tertiary.main,
                color: theme.palette.primary.main,
              },
              marginTop:'25px'
            }}>{buttonText}</Button>

      </div>
    </div>
  );
};

FlipCard.propTypes = {
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
};

export default FlipCard;
